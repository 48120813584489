body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(190, 190, 190);
}

a {
  color: #A7ABBA;
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  margin: unset;
  background: none !important;
}

ul {
  list-style-type: none;
  margin: unset;
  padding: unset;
}

html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
}
