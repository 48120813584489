/*primary*/
.primary {
  background: #454955;
  color: #FFF;
}

.primary:hover {
  background: #2C2E35;
  color: #FFF;
}

.primary:disabled {
  opacity: 0.35;
}

/*gradient*/
.gradient {
  background: linear-gradient(57.25deg, #BD1637 18.06%, #BD1637 18.06%, #A1234A 30.91%, #58427B 58.52%, #1560A8 82.54%);
  color: #fff;
}

.gradient:disabled {
  color: #fff;
  opacity: 0.35;
}

/*secondary*/
.secondary {
  color: #454955;
  border: 1px solid #454955;
}

.secondary:hover {
  background: #F3F3F3;
}

.secondary:disabled {
  background: #fff;
  border: 1px solid #454955;
  opacity: 0.35;
}

.secondary:enabled:active {
  background: rgba(83, 79, 122, 0.2);
  border: 1px solid #454955;
  box-shadow: none;
}
  