.loadingIndicator {
  background: transparent;
  height: 4px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}

.loadingIndicator:before {
  animation: loading 2s linear infinite;
  background: #5F636F;
  content: "";
  display: block;
  height: 4px;
  left: -200px;
  position: absolute;
  width: 200px;
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
