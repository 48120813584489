.MuiInput-root input:-webkit-autofill,
.MuiInput-root input:-webkit-autofill:hover,
.MuiInput-root input:-webkit-autofill:focus,
.MuiInput-root input:-webkit-autofill:active{
	-webkit-background-clip: text;
	-webkit-text-fill-color: rgba(44, 46, 53, 1);
}

.MuiInput-root.dark input:-webkit-autofill,
.MuiInput-root.dark input:-webkit-autofill:hover,
.MuiInput-root.dark input:-webkit-autofill:focus,
.MuiInput-root.dark input:-webkit-autofill:active{
	-webkit-background-clip: text;
	-webkit-text-fill-color: rgba(255, 255, 255, 1);
}
