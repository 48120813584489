/* In call dialer button effect*/
.pulseEffect {
  animation: pulse 2s infinite;
}

/* Rounded pulse effect - Green*/
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(65, 241, 97, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(65, 241, 97, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(65, 241, 97, 0);
  }
}