@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;400&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(190, 190, 190);
}

a {
  color: #A7ABBA;
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  margin: unset;
  background: none !important;
}

ul {
  list-style-type: none;
  margin: unset;
  padding: unset;
}

html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
}

.LoadingIndicator_loadingIndicator__Kwbc- {
  background: transparent;
  height: 4px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}

.LoadingIndicator_loadingIndicator__Kwbc-:before {
  animation: LoadingIndicator_loading__1emDi 2s linear infinite;
  background: #5F636F;
  content: "";
  display: block;
  height: 4px;
  left: -200px;
  position: absolute;
  width: 200px;
}

@keyframes LoadingIndicator_loading__1emDi {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

/*general*/
.Button_button__3Apwr {
  border-radius: 19.5px;
  color: #fff;
  cursor: pointer;
  outline: none;
}

.Button_button__3Apwr:enabled:hover {
  box-shadow: none;
}

.Button_button__3Apwr:enabled:hover > svg {
  pointer-events: none;
}

.Button_button__3Apwr:enabled:active {
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.25);
}

.Button_button__3Apwr:disabled {
  opacity: 0.35;
  cursor: not-allowed;
}

/*gradient*/
.Button_gradient__DFikJ {
  background: linear-gradient(57.25deg, #BD1637 18.06%, #BD1637 18.06%, #A1234A 30.91%, #58427B 58.52%, #1560A8 82.54%);
  color: #fff;
}

.Button_gradient__DFikJ:disabled {
  color: #fff;
}

/*secondary*/
.Button_secondary__t3CmO {
  color: #454955;
  border: 1px solid #454955;
  box-sizing: border-box;
}

.Button_secondary__t3CmO:disabled {
  background: #fff;
  border: 1px solid #454955;
  box-sizing: border-box;
}

.Button_secondary__t3CmO:enabled:active {
  background: rgba(83, 79, 122, 0.2);
  border: 1px solid #454955;
  box-sizing: border-box;
  box-shadow: none;
}

.Input_MuiInput-root__I4niw input:-webkit-autofill,
.Input_MuiInput-root__I4niw input:-webkit-autofill:hover,
.Input_MuiInput-root__I4niw input:-webkit-autofill:focus,
.Input_MuiInput-root__I4niw input:-webkit-autofill:active{
	-webkit-background-clip: text;
	-webkit-text-fill-color: rgba(44, 46, 53, 1);
}

.Input_MuiInput-root__I4niw.Input_dark__bFf0v input:-webkit-autofill,
.Input_MuiInput-root__I4niw.Input_dark__bFf0v input:-webkit-autofill:hover,
.Input_MuiInput-root__I4niw.Input_dark__bFf0v input:-webkit-autofill:focus,
.Input_MuiInput-root__I4niw.Input_dark__bFf0v input:-webkit-autofill:active{
	-webkit-background-clip: text;
	-webkit-text-fill-color: rgba(255, 255, 255, 1);
}

/*primary*/
.IconButton_primary__VNivC {
  background: #454955;
  color: #FFF;
}

.IconButton_primary__VNivC:hover {
  background: #2C2E35;
  color: #FFF;
}

.IconButton_primary__VNivC:disabled {
  opacity: 0.35;
}

/*gradient*/
.IconButton_gradient__2Wlvv {
  background: linear-gradient(57.25deg, #BD1637 18.06%, #BD1637 18.06%, #A1234A 30.91%, #58427B 58.52%, #1560A8 82.54%);
  color: #fff;
}

.IconButton_gradient__2Wlvv:disabled {
  color: #fff;
  opacity: 0.35;
}

/*secondary*/
.IconButton_secondary__3jvdr {
  color: #454955;
  border: 1px solid #454955;
}

.IconButton_secondary__3jvdr:hover {
  background: #F3F3F3;
}

.IconButton_secondary__3jvdr:disabled {
  background: #fff;
  border: 1px solid #454955;
  opacity: 0.35;
}

.IconButton_secondary__3jvdr:enabled:active {
  background: rgba(83, 79, 122, 0.2);
  border: 1px solid #454955;
  box-shadow: none;
}
  
.Dropdown_dropdown__3SDyp {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}

.Dropdown_openButton__hf9B2 {
  align-items: center;
  cursor: pointer;
  position: relative;

  /* TODO: tem que ser no componente button ou global.css */
  background: none;
  border-radius: 16px;
  color: #A7ABBA;
  outline: none;
  margin-right: 5px;
  padding: 0 14px;
}

.Dropdown_openButtonSelected__1G-cT {
  align-items: center;
  cursor: pointer;
  position: relative;

  /* TODO: tem que ser no componente button ou global.css */
  background: none;
  border-radius: 16px;
  color: #A7ABBA;
  outline: none;
  margin-right: 5px;
  padding: 0 14px;
}

.Dropdown_label__ybaGb {
  display: inline;
}

.Dropdown_arrow__2WGhz { /* TODO: deve estar no svgIcon.css  */
  fill: currentColor;
  height: 1em;
  margin-left: 4px;
  vertical-align: text-bottom;
  width: 1em;
}

.Dropdown_menu__1iyrz {
  max-height: 40vh;
  overflow-y: scroll;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
  /*min-width: 365px;*/
  overflow-x: hidden;
  padding: 8px;
  position: absolute;
  white-space: nowrap;
  z-index: var(--z-index-dropdown);
}

.Dropdown_menuRight__2qjg1 {
  right: 0;
}

.Dropdown_menuTop__2luwh {
  bottom: 100%;
}

.Dropdown_menu__1iyrz > a {
  text-decoration: none;
}

.Dropdown_menu__1iyrz > a,
.Dropdown_menu__1iyrz > button {
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: block;
  padding: 12px 16px;
  text-align: left;
  width: 100%;
}

.Dropdown_menu__1iyrz > a + a,
.Dropdown_menu__1iyrz > button + button {
  border-top: solid 1px #eaeaea;
}

.Dropdown_menu__1iyrz > button { /* TODO: global */
  background: none;
  border: none;
  outline: none;
}

.Dropdown_menu__1iyrz > a:hover,
.Dropdown_menu__1iyrz > button:hover {
  background: #47a8bd;
  border-radius: 4px;
  color: #FFFFFF;
}

/* Dark mode */
.Dropdown_dark__2bOj0.Dropdown_dropdown__3SDyp .Dropdown_menu__1iyrz {
	background-color: rgba(44, 46, 53, 1);
}
.Dropdown_dark__2bOj0.Dropdown_dropdown__3SDyp .Dropdown_menu__1iyrz > div * {
  color: #FFFFFF!important;
}

.Dropdown_dark__2bOj0.Dropdown_dropdown__3SDyp .Dropdown_openButton__hf9B2 {
  color: #FFFFFF;
}

.Dropdown_dark__2bOj0.Dropdown_dropdown__3SDyp .Dropdown_openButtonSelected__1G-cT {
  color: #FFFFFF;
}


.NotificationAttachmentsModal_containerDiv__1YG5e {
	display: flex;
	padding: 20px;
}

.NotificationAttachmentsModal_buttonsRow__3sK0U {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: -4vw;
	width: 60%;
	flex-wrap: wrap;
	border-right: 1px solid lightslategray;
}

.NotificationAttachmentsModal_buttonsRow__3sK0U button {
	border-bottom: 1px dashed #ccc;
	border-radius: 0;
	width: 100%;
	padding-bottom: 0;
	padding-top: 0;
}

.NotificationAttachmentsModal_content__14PxP {
	display: flex;
	justify-content: center;
	width: 80vw;
	margin-right: -4vw;
}

.NotificationAttachmentsModal_content__14PxP > div {
	box-shadow: none;
}

.NotificationAttachmentsModal_card__1lJlb {
	min-width: 60%;
}

.NotificationAttachmentsModal_cardContent__3zWbT h2 {
	text-align: center;
	word-wrap: break-word;
}

.NotificationAttachmentsModal_iconButton__2zpdu {
	position: relative;
}

.NotificationAttachmentsModal_iconButton__2zpdu > span {
	display: flex;
	justify-content: flex-start;
}

.NotificationAttachmentsModal_iconText__2X2wD {
	margin-left: 2px;
	font-size: small;
	font-family: 'Raleway', sans-serif;
	text-align: start;
	word-break: break-word;
}

.NotificationAttachmentsModal_media__pqVgV {
	display: flex;
	justify-content: center;
	width: 100%;
}

.NotificationAttachmentsModal_media__pqVgV > video,
.NotificationAttachmentsModal_media__pqVgV > img {
	max-height: 80vh;
	max-width: 100%;
}

.NotificationAttachmentsModal_downloadTextDiv__1I1Og {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 12vh;
	text-align: center;
}

.NotificationAttachmentsModal_download__2hzzQ {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (max-width: 1224px) {
	.NotificationAttachmentsModal_containerDiv__1YG5e {
		display: flex;
		flex-direction: column-reverse;
		height: 100%;
		align-items: center;
	}
	.NotificationAttachmentsModal_buttonsRow__3sK0U {
		width: 100%;
		height: 20%;
		display: flex;
		flex-direction: row;
		overflow-x: scroll;
		margin-left: 0;
		flex-wrap: nowrap;
		border-right: none;
	}
	.NotificationAttachmentsModal_buttonsRow__3sK0U button {
		border-bottom: none;
		border-radius: 0;
		width: auto;
	}
	.NotificationAttachmentsModal_iconButton__2zpdu {
		width: auto;
	}
	.NotificationAttachmentsModal_content__14PxP {
		height: 80%;
		padding: 5px;
	}
	.NotificationAttachmentsModal_media__pqVgV {
		width: 100%;
		height: 100%;
		display: flex;
		padding: 0px 0px;
	}
	.NotificationAttachmentsModal_media__pqVgV > img {
		max-height: 70vh;
	}
	.NotificationAttachmentsModal_media__pqVgV > video {
		max-width: 100%;
		width: 40vh;
		height: 100%;
	}
}

/* In call dialer button effect*/
.VoIpHeader_pulseEffect__3wZJA {
  animation: VoIpHeader_pulse__3b2UU 2s infinite;
}

/* Rounded pulse effect - Green*/
@keyframes VoIpHeader_pulse__3b2UU {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(65, 241, 97, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(65, 241, 97, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(65, 241, 97, 0);
  }
}
