/*general*/
.button {
  border-radius: 19.5px;
  color: #fff;
  cursor: pointer;
  outline: none;
}

.button:enabled:hover {
  box-shadow: none;
}

.button:enabled:hover > svg {
  pointer-events: none;
}

.button:enabled:active {
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.25);
}

.button:disabled {
  opacity: 0.35;
  cursor: not-allowed;
}

/*gradient*/
.gradient {
  background: linear-gradient(57.25deg, #BD1637 18.06%, #BD1637 18.06%, #A1234A 30.91%, #58427B 58.52%, #1560A8 82.54%);
  color: #fff;
}

.gradient:disabled {
  color: #fff;
}

/*secondary*/
.secondary {
  color: #454955;
  border: 1px solid #454955;
  box-sizing: border-box;
}

.secondary:disabled {
  background: #fff;
  border: 1px solid #454955;
  box-sizing: border-box;
}

.secondary:enabled:active {
  background: rgba(83, 79, 122, 0.2);
  border: 1px solid #454955;
  box-sizing: border-box;
  box-shadow: none;
}
