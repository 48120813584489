.dropdown {
  display: inline-block;
  position: relative;
  user-select: none;
}

.openButton {
  align-items: center;
  cursor: pointer;
  position: relative;

  /* TODO: tem que ser no componente button ou global.css */
  background: none;
  border-radius: 16px;
  color: #A7ABBA;
  outline: none;
  margin-right: 5px;
  padding: 0 14px;
}

.openButtonSelected {
  align-items: center;
  cursor: pointer;
  position: relative;

  /* TODO: tem que ser no componente button ou global.css */
  background: none;
  border-radius: 16px;
  color: #A7ABBA;
  outline: none;
  margin-right: 5px;
  padding: 0 14px;
}

.label {
  display: inline;
}

.arrow { /* TODO: deve estar no svgIcon.css  */
  fill: currentColor;
  height: 1em;
  margin-left: 4px;
  vertical-align: text-bottom;
  width: 1em;
}

.menu {
  max-height: 40vh;
  overflow-y: scroll;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
  /*min-width: 365px;*/
  overflow-x: hidden;
  padding: 8px;
  position: absolute;
  white-space: nowrap;
  z-index: var(--z-index-dropdown);
}

.menuRight {
  right: 0;
}

.menuTop {
  bottom: 100%;
}

.menu > a {
  text-decoration: none;
}

.menu > a,
.menu > button {
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: block;
  padding: 12px 16px;
  text-align: left;
  width: 100%;
}

.menu > a + a,
.menu > button + button {
  border-top: solid 1px #eaeaea;
}

.menu > button { /* TODO: global */
  background: none;
  border: none;
  outline: none;
}

.menu > a:hover,
.menu > button:hover {
  background: #47a8bd;
  border-radius: 4px;
  color: #FFFFFF;
}

/* Dark mode */
.dark.dropdown .menu {
	background-color: rgba(44, 46, 53, 1);
}
.dark.dropdown .menu > div * {
  color: #FFFFFF!important;
}

.dark.dropdown .openButton {
  color: #FFFFFF;
}

.dark.dropdown .openButtonSelected {
  color: #FFFFFF;
}

